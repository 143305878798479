// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
// @import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// @import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
// @import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
// @import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
// .brand-dark {
//   @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
// }
// // Light
// .brand-light {
//   @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
// }

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

* {
  list-style: none;
  padding: 0;
  margin: 0;
}

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}
.error-font {
  font-size: 1rem !important;
}

.icon-button {
  background-color: transparent;
  border: none;
  padding: none;
  margin: none;
}

.empty-list {
  width: 100%;
  text-align: center;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-header-with-icon {
  display: flex;
  align-items: center;

  span {
    margin-left: 10px;
    text-overflow: ellipsis;
    overflow: hidden;

    @media screen and (max-width: 470px) {
      max-width: 300px;
    }

    @media screen and (max-width: 375px) {
      max-width: 200px;
    }
  }
}

.tooltip-icon {
  color: #808080;
  cursor: pointer;

  &:hover {
    color: #000;
  }
}

.text-dark-danger {
  color: #9a0717;
}

.card-heavy-border {
  border-radius: 30px !important;
  border: 3px solid #000 !important;
}
* {
  font-family: "Roboto", sans-serif;
}

@media print {
  .MuiGrid-root {
    page-break-after: always !important;
    page-break-before: always !important;
    display: block !important;
  }

  .d-print-none {
    display: none !important;
  }

  .chart-container {
    padding: 0 !important;
  }

  .card-heavy-border {
    background-color: red !important;
    display: block !important;
    page-break-after: always !important;
    page-break-before: always !important;
  }

  img {
    page-break-inside: avoid;
  }
}

.printable-container {
  // page-break-before: always !important;
  // page-break-inside: avoid !important;
}

.yearly-table-body {
  background-color: #f7f7f7;
  border-bottom: 1px solid #ccc;
}

.popover-paper {
  width: 150px;
  padding: 10px 0;
  
  .menu-item {
      padding: 10px 20px;
      font-size: 1.2rem;
  }
}


.bg-curve-a {
  background-color: #0883E8  !important;
}

.bg-light-curve-a {
  background-color: rgba(8, 131, 232, 0.5) !important;
}

.text-curve-a {
  color: #0883E8 !important;
}

.bg-curve-b {
  background-color: #56AECA !important;
}

.bg-light-curve-b {
  background-color: rgba(86, 174, 202, 0.5) !important;
}

.text-curve-b {
  color: #56AECA !important;
}

.bg-buy-necessity {
  background-color: #0080C0 !important;
}

.bg-light-buy-necessity {
  background-color: #00B0F0 !important;
}

.bg-without-turn {
  background-color: #9E1F1F !important;
}

.bg-light-without-turn {
  background-color: #CF5050 !important;
}

.bg-curve-c {
  background-color: #CFA115 !important;
}

.bg-light-curve-c {
  background-color: #FFD145 !important;
}

.text-curve-c {
  color: #CFA115 !important;
}

.hiddenCollapse {
  max-width: 0px;
}
