.actions-container {
    display: flex;
    align-items: flex-start !important;
}

.time-filter-container {
    margin-bottom: 20px;
}

@media screen and (max-width: 680px) {
    .actions-container {
        flex-direction: column;
        margin-top: 20px !important;
    }

    .filters-container {
        margin-bottom: 20px;
    }
}
